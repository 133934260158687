.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__Content {
    background-color: white;
    padding: 2rem;
    text-align: left;
    max-width: 30rem;
    width: 100%;
  }
}
