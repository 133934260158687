.Login {
  &__Container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20rem;
    max-width: 27.5rem;
    background-color: white;

    &__Header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #303030;
      width: 100%;
      height: 130px;
      text-transform: uppercase;
      color: #f1f1f1;
      &__Title {
        font-size: 1.4rem;
        font-weight: 600;
      }
      &__Subtitle {
        margin-top: 0.5rem;
        font-size: 1rem;
      }

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -20px;
        width: 0;
        height: 0;
        border-top: solid 20px #303030;
        border-left: solid 20px transparent;
        border-right: solid 20px transparent;
        clear: both;
      }
    }

    &__Content {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 1rem 1rem 1rem;
    }

    &__Form {
      width: 100%;
      text-align: center;
    }
  }
}
