body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  .MuiToggleButton-root {
    color: (0, 0, 0, 0.54);
    background-color: white;
    padding: 7px 20px;
    &.Mui-selected {
      color: (0, 0, 0, 0.54);
      background-color: white;
      padding-bottom: 4px;
      border-bottom: 4px solid #8f1f65;
    }
  }

  .MuiAccordion-rounded {
    border-radius: 4px;
  }
}
