.ProjectPage {
  fieldset {
    width: 100%;
  }
  &-engine {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    canvas {
      outline: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
  }
  .MuiAccordion-root:last-child {
    &.Mui-expanded {
      margin-bottom: 1.5rem;
    }
    margin-bottom: 1.5rem;
  }

  .wideItem {
    text-transform: uppercase;
    align-self: center;
    justify-self: center;
  }

  .textInfo {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .openingWidthPresetButton {
    font-size: 0.7rem;
  }

  .MuiFormLabel-filled {
    background-color: white;
  }

  .buttonGroupButtonSmall {
    flex-basis: 0;
  }
}
